// extracted by mini-css-extract-plugin
export var root = "PlasmicLinkButton-module--root--TwnoS";
export var root__type_solidBlue = "PlasmicLinkButton-module--root__type_solidBlue--cVvqD";
export var root__type_solidBlack = "PlasmicLinkButton-module--root__type_solidBlack--tMrbS";
export var root__type_outlineBlue = "PlasmicLinkButton-module--root__type_outlineBlue--2fru8";
export var root__type_small = "PlasmicLinkButton-module--root__type_small--3CK-7";
export var root__narrower = "PlasmicLinkButton-module--root__narrower--kr5Iq";
export var slotText = "PlasmicLinkButton-module--slotText--3QQ_j";
export var slotText__type_solidBlue = "PlasmicLinkButton-module--slotText__type_solidBlue--Pg-cX";
export var slotText__type_solidBlack = "PlasmicLinkButton-module--slotText__type_solidBlack--XMiDQ";
export var slotText__type_outlineBlue = "PlasmicLinkButton-module--slotText__type_outlineBlue--33i6f";