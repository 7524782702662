// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--oyEUz";
export var box__rObLf = "PlasmicFooter-module--box__rObLf--jcR5E";
export var box__yaYdS = "PlasmicFooter-module--box__yaYdS--1jTXP";
export var box__dux1V = "PlasmicFooter-module--box__dux1V--zbSXm";
export var iconLink__exmMt = "PlasmicFooter-module--iconLink__exmMt--2JwDs";
export var svg__o9Lt9 = "PlasmicFooter-module--svg__o9Lt9--2tKzM";
export var box__gfp5T = "PlasmicFooter-module--box__gfp5T--3dME0";
export var link___90Sdz = "PlasmicFooter-module--link___90Sdz--2uBeV";
export var box__msYzL = "PlasmicFooter-module--box__msYzL--33KwV";
export var link___7X4Ds = "PlasmicFooter-module--link___7X4Ds--1ZrAa";
export var box__zxyj4 = "PlasmicFooter-module--box__zxyj4--2O4Qu";
export var box__uu3WO = "PlasmicFooter-module--box__uu3WO--1KLOM";
export var box__qANwR = "PlasmicFooter-module--box__qANwR--1LrDy";
export var link__jJhId = "PlasmicFooter-module--link__jJhId--8GdMi";
export var link__rEzeN = "PlasmicFooter-module--link__rEzeN--N2AlK";
export var link__gAjij = "PlasmicFooter-module--link__gAjij--3-Sa0";
export var link__zg8Vm = "PlasmicFooter-module--link__zg8Vm--1G2-Y";
export var link__mrExJ = "PlasmicFooter-module--link__mrExJ--1YqbP";
export var box__s3Ub = "PlasmicFooter-module--box__s3Ub--J5eUN";
export var box___8HubW = "PlasmicFooter-module--box___8HubW--OyPoh";
export var link__rtJzO = "PlasmicFooter-module--link__rtJzO--3R86Q";
export var link__j2Kg = "PlasmicFooter-module--link__j2Kg--mbZ6O";
export var link__hYXhO = "PlasmicFooter-module--link__hYXhO--1NIDV";
export var link__klbCt = "PlasmicFooter-module--link__klbCt--2_e2V";
export var link___7NaLo = "PlasmicFooter-module--link___7NaLo--1mV5s";
export var box__jIvLo = "PlasmicFooter-module--box__jIvLo--1luR9";
export var box__laIxN = "PlasmicFooter-module--box__laIxN--SVgSF";
export var link__nDuX = "PlasmicFooter-module--link__nDuX--2bY23";
export var link__bnEv3 = "PlasmicFooter-module--link__bnEv3--3Xbuy";
export var link__sucEe = "PlasmicFooter-module--link__sucEe--21Swe";
export var link__fcCAf = "PlasmicFooter-module--link__fcCAf--3saix";
export var link__w6Xav = "PlasmicFooter-module--link__w6Xav--1-AUq";
export var box___2Kx5X = "PlasmicFooter-module--box___2Kx5X--2qCJw";
export var box__bKw = "PlasmicFooter-module--box__bKw--3jeGC";
export var box__uwJ1 = "PlasmicFooter-module--box__uwJ1--3d3Pw";
export var box__jSnSo = "PlasmicFooter-module--box__jSnSo--k6-Yr";
export var textbox = "PlasmicFooter-module--textbox--1O3a3";
export var box__ps5E4 = "PlasmicFooter-module--box__ps5E4--3E2DR";
export var button = "PlasmicFooter-module--button--27DKR";
export var svg___2KYkP = "PlasmicFooter-module--svg___2KYkP--3fBDi";
export var box__vXtj5 = "PlasmicFooter-module--box__vXtj5--1W4GG";
export var box__xSUcT = "PlasmicFooter-module--box__xSUcT--Df_4N";
export var box__bkXz6 = "PlasmicFooter-module--box__bkXz6--3hW_d";
export var link___574PF = "PlasmicFooter-module--link___574PF--RrCLx";
export var link__z9RBt = "PlasmicFooter-module--link__z9RBt--O6fZQ";
export var box__uZbeY = "PlasmicFooter-module--box__uZbeY--2zJ_8";
export var box__oRtro = "PlasmicFooter-module--box__oRtro--3c2Py";
export var iconLink__uyKjy = "PlasmicFooter-module--iconLink__uyKjy--3kBz3";
export var svg__g3Dg9 = "PlasmicFooter-module--svg__g3Dg9--3oe1z";
export var iconLink__hhozp = "PlasmicFooter-module--iconLink__hhozp--1As2G";
export var svg___60Rfp = "PlasmicFooter-module--svg___60Rfp--VRJt1";
export var iconLink__tvHt = "PlasmicFooter-module--iconLink__tvHt--1WqWg";
export var svg__zElWg = "PlasmicFooter-module--svg__zElWg--3IrmR";